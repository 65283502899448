import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueSocialSharing from 'vue-social-sharing'
import VueGtag from 'vue-gtag'

const app = createApp(App).use(router).use(VueSocialSharing)
if (process.env.VUE_APP_GA_ID) {
  app.use(VueGtag, {
    config: {
      id: process.env.VUE_APP_GA_ID
    }
  })
}

app.mount('#app')
