import { cameraId } from '@/store/user'

/**
 * Detect mobile device
 */
function detectMobile() {
  return (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  )
}

export const isMobile = detectMobile()

export function hexToRgb(
  hex: string,
  normalized = false
): [number, number, number] | null {
  const divider = normalized ? 255 : 1
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? [
        parseInt(result[1], 16) / divider,
        parseInt(result[2], 16) / divider,
        parseInt(result[3], 16) / divider
      ]
    : null
}

export type ColorRGB =
  | [number, number, number]
  | { r: number; g: number; b: number }
export type ColorRGBA =
  | [number, number, number, number]
  | { r: number; g: number; b: number; a: number }

export function normalizeRgba<T extends ColorRGB | ColorRGBA>(color: T): T {
  if (Array.isArray(color)) {
    // @ts-ignore
    return color.map(rgb => rgb / 255)
  } else {
    return Object.entries(color).reduce((acc, [key, value]) => {
      if (value === undefined) return acc
      // @ts-ignore
      acc[key as keyof RGBA] = value / 255
      return acc
    }, {} as typeof color)
  }
}

function isMediaError(e: any): e is { name: string } {
  return typeof e === 'object' && e !== null && 'name' in e
}

export async function getStreamFromMainCamera() {
  if (cameraId.value) {
    try {
      return navigator.mediaDevices.getUserMedia({
        video: { deviceId: { exact: cameraId.value } }
      })
    } catch (e) {
      if (isMediaError(e) && e.name === 'OverconstrainedError') {
        cameraId.value = undefined
      } else {
        throw e
      }
    }
  }

  // Ask for permissions by initializing fake stream (on iOS before permission is given no deviceIds are available)
  const fakeStream = await navigator.mediaDevices.getUserMedia({
    video: true,
    audio: false
  })
  // Stop streaming (on Android you cannot ask for a new stream before previous is stopped)
  fakeStream.getTracks().forEach(track => track.stop())

  // Get only video inputs
  const videoDevices = await navigator.mediaDevices
    .enumerateDevices()
    .then(devices => devices.filter(device => device.kind === 'videoinput'))

  // Open every video device and check for main one by predefined characteristics
  async function findStreamWithTorchFromDevices(
    devices: MediaDeviceInfo[],
    index = 0
  ): Promise<MediaStream | undefined> {
    if (index === devices.length) return
    const device = devices[index]

    const stream = await navigator.mediaDevices.getUserMedia({
      video: { deviceId: { exact: device.deviceId } }
    })

    for (const track of stream.getVideoTracks()) {
      const capabilities = track.getCapabilities()
      // @ts-ignore
      if (capabilities.torch) return stream
    }

    stream.getTracks().forEach(track => track.stop())
    return await findStreamWithTorchFromDevices(devices, index + 1)
  }

  // If no torch - could mean that it's an iPhone
  let stream = await findStreamWithTorchFromDevices(videoDevices)
  if (!stream) {
    // Get stream ideal for iPhone
    stream = await navigator.mediaDevices.getUserMedia({
      video: {
        facingMode: {
          exact: 'environment'
        },
        height: {
          ideal: 1620
        },
        width: {
          ideal: 1620
        }
      },
      audio: false
    })
  }
  cameraId.value = stream.getTracks()[0].getSettings().deviceId
  return stream
}
